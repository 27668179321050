@tailwind base;
@tailwind components;
@tailwind utilities;

/* Web Fonts */

@font-face {
  font-family: 'Arial Bold';
  src: url('/Arial-BoldMT.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Arial Mono';
  src: url('/ArialMonoMTPro-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ABC Maxi Sharp Mono';
  src: url('/ABCMaxiSharpMono-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  button {
    letter-spacing: inherit;
  }
  html {
    @apply font-medium;
    @apply text-black;
    /* @apply text-md; */

    /* smooth scrolling to anchors, next page, etc */
    /* with SPA model it's kind of annoying... */
    /* scroll-behavior: smooth; */
  }
  body {
    /* @apply bg-white; */
    @apply bg-[#eadcff];
    font-feature-settings:
      'rlig' 1,
      'calt' 1;
  }
  code {
    padding: theme('padding.1') theme('padding.1') 0px;
    background: color-mix(in srgb, currentColor 20%, transparent);
  }
}

@layer components {
  /*
   * FAT new shit
   */

  /* active page indicator */
  .linkTextNavigation {
    @apply hover:text-fatYellow
    /* @apply decoration-[0.1em] underline-offset-[0.1em] hover:underline; */;
  }
  a.active,
  .linkCartActive {
    @apply text-fatYellow;
  }

  /* dropdowns from shadcn-ui */
  [data-radix-popper-content-wrapper] {
    width: var(--radix-popper-anchor-width);
    top: 7px;
  }

  .portableText {
    ol {
      list-style-type: decimal;
    }
    ol ol {
      list-style-type: lower-alpha;
    }
    ol ol ol {
      list-style-type: lower-roman;
    }

    ul {
      list-style: disc;
    }
    ul ul {
      list-style: circle inside;
    }
    ul ul ul {
      list-style: square inside;
    }

    .list-none {
      list-style-type: none;
    }
  }

  .select {
    align-items: center;
    /* background-color: theme('colors.white'); */
    /* border-radius: theme('borderRadius.full'); */
    /* color: theme('colors.black'); */
    display: flex;
    font-size: theme('fontSize.xs');
    font-weight: theme('fontWeight.bold');
    height: 2.5rem;
    justify-content: center;
    letter-spacing: theme('letterSpacing.normal');
    overflow: hidden;
    padding: theme('padding.4');
    /* transition: all 200ms ease-out; */
    text-transform: uppercase;
  }

  .select:disabled {
    color: theme('colors.darkGray');
  }

  .select:hover:not(:disabled) {
    opacity: 0.9;
  }

  address {
    display: inline-block;
    font-style: normal;
    text-decoration-line: inherit;
  }
}

@layer utilities {
  .pause {
    animation-play-state: paused;
  }
}

* {
  font-variant-ligatures: none;
}

html {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

/*
 * FAT new shit
 */

/* grid tile borders */
.gridTile,
.gridTile--text {
  box-shadow: 0 0 0 1px #000;
}
@media screen(md) {
  .md\:gridTile,
  .md\:gridTile--text {
    box-shadow: 0 0 0 1px #000;
  }
}

/* lazyFATImage */
.lazyFATImage {
  background: #000;
}
.image_lqip {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  image-rendering: pixelated;
  opacity: 1;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  transition: opacity 0.1s ease-out;
  width: 100%;
}
/* .image_lqip[data-is-loaded="true"] {
      opacity: 1;
    } */
.image_hires {
  /* opacity: 0; */
  position: relative;
  z-index: 2;
  transition: opacity 0.2s 0.2s ease-out;
}
/* .image_hires[data-fatloaded="true"]{
      opacity: 1;
    } */

/* FAT Timecapsule */
.border-reticule {
  --size: 12px; /* corner arrow size */
  --width: 2px; /* the border width */
  --gap: 8px; /* the gap between the border and image */

  padding: calc(var(--gap) + var(--width));
  outline: var(--width) solid currentColor;
  outline-offset: calc(-1 * var(--width));
  mask:
    conic-gradient(at var(--size) var(--size), #0000 75%, #000 0) 0 0 /
      calc(100% - var(--size)) calc(100% - var(--size)),
    linear-gradient(#000 0 0) content-box;
}
/* .border-reticule:hover {
  outline-offset: calc(var(--gap)/-4);
} */

@media screen(md) {
  .border-reticule {
    --size: 16px; /* corner arrow size */
    --width: 2px; /* the border width */
    --gap: 24px; /* the gap between the border and image */
  }
  /* .border-reticule:hover {
    outline-offset: calc(var(--gap)/-8);
  } */
}

/* FAT Marquizzle */

.fatMarquee {
  /* --gap: 1rem; */
  --gap: 0rem;
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.fatMarquee__content {
  display: flex;
  flex-shrink: 0;
  min-width: 100%;
  flex-direction: column;
  justify-content: space-around;
  gap: var(--gap);
  animation: scroll-x var(--duration) linear infinite;
}
.fatMarquee:hover .fatMarquee__content {
  animation-play-state: paused;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquizzle__content {
    animation-play-state: paused;
  }
}
